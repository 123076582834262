<template>
  <div class="project-table">
    <div class="fontSize20gay text-left">知识库管理</div>
    <div class="resource-outer-container marginTop20px">
      <div class="side-tree">
        <!--<el-tree :data="dataSource"-->
        <!--         show-checkbox-->
        <!--         node-key="id"-->
        <!--         default-expand-all-->
        <!--         :expand-on-click-node="false"-->
        <!--         :render-content="renderContent"-->
        <!--         style="width: 100%;"-->
        <!--/>-->
        <el-tree ref="elTree"
                 :data="dataSource"
                 node-key="id"
                 :current-node-key="currentSelectId"
                 :expand-on-click-node="false"
                 :render-content="renderContent"
                 :props="{
                   label: 'name',
                   children: 'child'
                 }"
                 @node-click="nodeClickHandler"
        />
        <div class="flex justify-start marginTop10px">
          <el-button type="primary"
                     link style="color: #3769B9;"
                     @click="addResourceFirst">新建知识库</el-button>
          <el-button v-if="0"
                     type="primary"
                     link style="color: #3769B9;"
                     @click="selectTest">选择结点</el-button>
        </div>
      </div>
      <div class="resource-container">
        <div class="resource-container-header flex justify-between">
          <div class="text-left">
            {{ currNodeText }}
          </div>
          <div>
            <button class="addBtn marginLR10" @click="formVisibleUpload = true"> + 上传</button>
          </div>
        </div>
        <div v-if="currNode" class="resource-content-container">
          <div class="resource-item"
               v-for="iter in currNode.data.resourceFiles"
               :key="iter.id">
            <div class="avatar-container">
              <div v-show="iter.fileType == 0">
                <img style="width: 90px; height: 90px;border-radius: 8px;"
                     src="@/static/img/exam/video2.png">
              </div>
              <div v-show="iter.fileType == 1">
                <img style="width: 90px; height: 90px;border-radius: 8px;"
                     src="@/static/img/exam/pdf.png">
              </div>
            </div>
            <div class="marginTop20px info-box">
              <div class="resource-title">{{ iter.fileName }}</div>
              <div class="resource-time marginTop10px">{{ iter.time }}</div>
            </div>
            <div class="action-layer">
              <div>
                <!-- 预览 -->
                <el-icon class="font-class" @click="previewVideo(projectName + iter.fileUrl, iter.fileType == 1 ? 'pdf' : 'video')"><Search /></el-icon>
              </div>
              <div class="marginTop20px">
                <!-- 删除 -->
                <el-icon class="font-class" @click="removeFile(iter)"><DeleteFilled /></el-icon>
              </div>
            </div>
          </div>
          <div v-show="currNode?.data?.resourceFiles?.length == 0" style="width: 100%;">
            <el-empty description="暂无资源" />
          </div>
        </div>
        <div v-show="!currNodeText" class="empty-box">
          <el-empty description="选择知识库管理资源" />
        </div>
      </div>
    </div>
    <!-- 操作窗口 -->
    <el-dialog v-model="dialogFormVisible"
               title="编辑知识库"
               class="info-div600"
               destroy-on-close
               style="padding: 24px;text-align: left">
      <el-form :model="pageFormCopy" :rules="pageFormCopyRules" ref="pageFormCopyRef">
        <el-form-item label="知识库名称" prop="name" size="large" label-width="120px">
          <el-input v-model="pageFormCopy.name"
                    autocomplete="off"
                    placeholder="请填写知识库名称"
                    class="room-input-tl" />
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <button class="subBtn" @click="submit">提交</button>
          <button class="qxBtn" @click="dialogFormVisible = false">取消</button>
      </span>
      </template>
    </el-dialog>
    <el-dialog v-model="formVisibleUpload"
               title="资源上传"
               class="info-div600"
               destroy-on-close
               style="padding: 24px;text-align: left">
      <el-form :model="pageForm" :rules="pageFormRules" ref="pageFormRef">
        <el-form-item label="上传到知识库" size="large" label-width="120px">
          <div>{{ currNodeText }}</div>
        </el-form-item>
        <el-form-item label="文件类型" required prop="fileType" size="large" label-width="120px">
          <el-select v-model="pageForm.fileType"
                     @change="waitUploadFile = null"
                     style="width: 100%;"
                     placeholder="选择分类"
                     size="large">
            <el-option label="视频(H.264/AVC编码)" :value="0"/>
            <el-option label="pdf" :value="1"/>
          </el-select>
        </el-form-item>
        <el-form-item label="资源名称" required prop="fileName" size="large" label-width="120px">
          <el-input v-model="pageForm.fileName"
                    autocomplete="off"
                    placeholder="请填写资源名称"
                    class="room-input-tl" />
        </el-form-item>
        <el-form-item label="选择资源" required size="large" label-width="120px">
          <div>
            <div v-if="waitUploadFile"
                 class="select-resource-item">
              <div>
                <img v-show="waitUploadFile.type === 'pdf'" height="64" width="64" src="@/static/img/exam/pdf.png" alt="">
                <img v-show="waitUploadFile.type === 'video'" height="64" width="64" src="@/static/img/exam/video2.png" alt="">
              </div>
              <div class="flex justify-center align-center select-resource-item-hover">
                <div @click="previewVideo(waitUploadFile.url, waitUploadFile.type)"
                     class="flex align-center justify-center">
                  <el-icon style="font-size: 18px;color: #fff;"><ZoomIn /></el-icon>
                </div>
                <el-icon style="font-size: 18px;color: #fff;"
                         class="marginLeft5"
                         @click="waitUploadFile = null"><DeleteFilled /></el-icon>
              </div>
            </div>
            <div>
              <el-button type="primary"
                         link style="color: #3769B9;"
                         @click="selectFile">选择文件</el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <button class="subBtn" @click="submitResource">提交</button>
          <button class="qxBtn" @click="formVisibleUpload = false">取消</button>
      </span>
      </template>
    </el-dialog>
    <el-dialog v-model="videoPreviewShow"
               title="视频预览"
               class="info-div700"
               destroy-on-close
               style="padding: 24px;text-align: left">
      <div>
        <video controls style="width: 100%;height: 306px;">
          <!--<source src="/media/cc0-videos/flower.webm" type="video/webm" />-->
          <!--<source src="/media/cc0-videos/flower.mp4" type="video/mp4" />-->
          <source :src="videoPreviewUrl" type="video/mp4" />
        </video>
      </div>
      <template #footer>
      <span class="dialog-footer">
        <button class="qxBtn" @click="videoPreviewShow = false">关闭</button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  addResource,
  addResourceFile,
  deleteResourceDir,
  editResource,
  getResourceTree,
  removeResourceFile
} from "@/api/examination";
import {uploadFile} from "@/api/api";
import {projectName} from "@/utils/config";

export default {
  name: "resourceList",
  components: {  },
  data(){
    return {
      projectName,
      url: "",
      id: 1000,
      dataSource: [
        // {
        //   id: 1,
        //   label: 'Level one 1',
        //   children: [
        //     {
        //       id: 4,
        //       label: 'Level two 1-1',
        //       children: [
        //         {
        //           id: 9,
        //           label: 'Level three 1-1-1',
        //         },
        //         {
        //           id: 10,
        //           label: 'Level three 1-1-2',
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
      // 预览
      videoPreviewShow: false,
      videoPreviewUrl: '',
      // 文件上传
      formVisibleUpload: false,
      waitUploadFile: null,
      pageForm: {
        fileName: '',
        fileType: 0,
      },
      pageFormRules: {
        fileName: [
          {required: true, message: '请填写资源名称', trigger: 'blur'},
        ],
        fileType: [
          {required: true, message: '请选择资源类型', trigger: 'blur'},
        ],
      },
      // 知识库创建弹窗
      dialogFormVisible: false,
      currCatalogue: null,
      // 当前结点位置
      currNodeText: '',
      currNode: null,
      currentSelectId: '',
      // 是否编辑
      editId: null,
      pageFormCopy: {
        name: ''
      },
      pageFormCopyRules: {
        name: [
          {required: true, message: '请填写知识库名称', trigger: 'blur'},
        ],
      },
    }
  },
  mounted() {
    this.getTree()
  },
  methods: {
    /**
     * 结点选择测试
     */
    selectTest(){
      // 查询结点
      const node = this.$refs.elTree.getNode(12)
      // 展开结点
      this.$refs.elTree.setCurrentKey(node.data.id)
    },
    /**
     * 预览
     */
    previewVideo(url, type='video'){
      switch (type) {
        case 'video':
          this.videoPreviewUrl = url
          this.videoPreviewShow = true
          break
        case 'pdf':
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.target = '_blank'
          // file.download = decodeURI(name);
          document.body.appendChild(a);
          a.click();
          // document.body.removeChild(file);
          break
      }
    },
    /**
     * 删除资源
     */
    removeFile(item){
      removeResourceFile(item.id).then(r => {
        if(r.success){
          this.$root.ElMessage({ type: 'success',message: r.message })
          this.getTree(() => {
            // 上传后处理
            this.$nextTick(() => {
              // 展开上传的结点目录
              this.$refs.elTree.setCurrentKey(this.currNode.data.id)
              // 更新为当前选择的结点
              this.currNode = this.$refs.elTree.getNode(this.currNode.data.id)
            })
          })
        } else {
          this.$root.ElMessage.error(r.message)
        }
      })
    },
    getTree(handler){
      getResourceTree().then(res => {
        this.dataSource = res.data
        // 展开更新结点
        handler && handler()
      })
    },
    /**
     * 查询知识库资源
     */
    nodeClickHandler(...args){
      let node = args[1]
      this.currNode = node

      // 路径标题生成
      const currRes = []
      while (node.parent && !Array.isArray(node.parent.data)){
        currRes.unshift(node.data.name)
        node = node.parent
      }
      if (!Array.isArray(node.data)) {
        currRes.unshift(node.data.name)
      }
      this.currNodeText = currRes.join(' / ')
    },
    /**
     * 添加知识库 顶级
     */
    addResourceFirst(){
      this.dialogFormVisible = true
      this.currCatalogue = null
    },
    /**
     * 文件上传
     */
    selectFile(){
      // 创建隐藏的文件上传input
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.style.display = 'none';
      document.body.appendChild(fileInput);

      let type = 'video'
      switch (String(this.pageForm.fileType)) {
        case '0':
          // 视频
          fileInput.accept = 'video/mp4,video/x-m4v,video/avi,video/x-flv'
          break
        case '1':
          // pdf
          fileInput.accept = '.pdf'
          type = 'pdf'
          break
      }

      // 触发点击事件,唤起文件选择对话框
      fileInput.click();

      // 监听选择的文件
      fileInput.onchange = (e) => {
        // 获取选择的文件
        const files = e.target.files;
        this.waitUploadFile = {
          value: files[0],
          type: type,
          url: URL.createObjectURL(files[0])
        }
      }
    },
    /**
     * 资源上传到知识库
     */
    submitResource(){
      const resHandler = (res, handler, delayHandler) => {
        if(res.success){
          this.$root.ElMessage({type:'success',message:res.message})
          handler && handler()
          setTimeout(() => {
            delayHandler && delayHandler()
          }, 500)
        }else{
          this.$root.ElMessage.error(res.message)
        }
      }

      this.$refs.pageFormRef.validate((valid, error) => {
        if (valid) {
          if (this.waitUploadFile === null) {
            this.$root.ElMessage.error('请选择上传的文件资源')
            return
          }
          // 文件上传
          const formData = new FormData()
          formData.append('file', this.waitUploadFile.value)
          uploadFile(formData).then(res => {
            if(res.success){
              // 资源上传到知识库
              addResourceFile({
                ...this.pageForm,
                fileUrl: res.data,
                resourceId: this.currNode.data.id
              }).then(r => {
                resHandler(r, () => {
                  this.formVisibleUpload = false
                  this.waitUploadFile = null
                  for (let key in this.pageForm) {
                    this.pageForm[key] = ''
                  }
                }, () => {
                  this.getTree(() => {
                    // 上传后处理
                    this.$nextTick(() => {
                      // 展开上传的结点目录
                      this.$refs.elTree.setCurrentKey(this.currNode.data.id)
                      // 更新为当前选择的结点
                      this.currNode = this.$refs.elTree.getNode(this.currNode.data.id)
                    })
                  })
                })
              })
            } else {
              this.$root.ElMessage.error(res.message)
            }
          })
          // 知识库更新
        } else {
          const key = Object.keys(error)[0]
          const msg = error[key][0].message
          this.$root.ElMessage.error(msg)
        }
      })
    },
    /**
     * 知识库编辑提交
     */
    submit(){
      const resHandler = (res, handler, delayHandler) => {
        if(res.success){
          this.$root.ElMessage({type:'success',message:res.message})
          handler && handler()
          setTimeout(() => {
            delayHandler && delayHandler()
          }, 500)
        }else{
          this.$root.ElMessage.error(res.message)
        }
      }

      this.$refs.pageFormCopyRef.validate((valid, error) => {
        if (valid) {
          // console.log(this.currCatalogue, '--------currCatalogue--');
          let summitData = { ...this.pageFormCopy }
          if (this.currCatalogue) {
            summitData.parentId = this.currCatalogue.id
          }
          // 编辑|创建
          let handler = this.editId === null ? addResource : editResource
          // 编辑
          if (this.editId) {
            summitData.parentId = undefined
            if (this.currCatalogue.parentId) {
              summitData.parentId = this.currCatalogue.parentId
            }
            summitData.id = this.editId
          }

          handler(summitData).then(res => {
            resHandler(res,
                () => {
                  this.dialogFormVisible = false
                  this.pageFormCopy.name = ''
                },
                () => {
                  this.getTree(() => {
                    // 上传后处理
                    this.$nextTick(() => {
                      // 展开上传的结点目录
                      if (this.editId) {
                        this.$refs.elTree.setCurrentKey(this.currNode.data.id)
                      } else {
                        if(this.currNode && this.currNode.data.child && this.currNode.data.child.length > 0){
                          const n = this.currNode.data.child[0]
                          this.$refs.elTree.setCurrentKey(n.id)
                        } else {
                          this.currNode && this.$refs.elTree.setCurrentKey(this.currNode.data.id)
                        }
                      }
                      // 更新为当前选择的结点
                      this.currNode = this.$refs.elTree.getNode(this.currNode.data.id)
                      //
                      if (this.editId) {
                        this.editId = null
                      }
                    })
                  })
            })
          })
        } else {
          const key = Object.keys(error)[0]
          const msg = error[key][0].message
          this.$root.ElMessage.error(msg)
        }
      })
    },
    //-------------
    append(data){
      const newChild = { id: this.id++, label: 'testtest', children: [] }
      if (!data.children) {
        data.children = []
      }
      data.children.push(newChild)
      this.dataSource = [...this.dataSource]
    },
    /**
     * 删除知识库
     */
    remove(node, data){
      // const parent = node.parent
      // const children = parent.data.children || parent.data
      // const index = children.findIndex((d) => d.id === data.id)
      // children.splice(index, 1)
      // this.dataSource = [...this.dataSource]
      //
      // console.log(node, data);
      // console.log(data.id, '------data-');

      this.$messageBox.confirm(
          '删除知识库后, 知识库里的资源也会一并删除, 确认删除吗?',
          '温馨提示',
          {
            confirmButtonText: '删除',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        if (node.parent) {
          this.currNode = node.parent
        }

        deleteResourceDir(data.id).then(() => {
          this.$message.success('操作成功')

          setTimeout(() => {
            this.getTree(() => {
              this.$nextTick(() => {
                if(this.currNode && this.currNode.data.child && this.currNode.data.child.length > 0){
                  const n = this.currNode.data.child[0]
                  this.$refs.elTree.setCurrentKey(n.id)
                } else {
                  this.currNode && this.$refs.elTree.setCurrentKey(this.currNode.data.id)
                }
                // 更新为当前选择的结点
                this.currNode = this.$refs.elTree.getNode(this.currNode.data.id)
              })
            })
          }, 500)
        })
      })
    },
    renderContent( h, { node, data, store}){
      return h( 'span',
          {
            class: 'custom-tree-node',
          },
          h('span', null, node.label),
          h('span', null, [
              h(
              'a',
              {
                onClick: () => {
                  // this.currNode = store.currentNode
                  // console.log(node, data, store, '--------33---');
                  try {
                    this.nodeClickHandler(null, store.currentNode)
                  } catch (e){

                  }

                  this.currCatalogue = data
                  this.pageFormCopy.name = ''
                  this.editId = null
                  this.dialogFormVisible = true
                },
                class: 'add-resource',
              },
              '新建'),
              h(
                'a',
                {
                  onClick: () => {
                    // this.currNode = store.currentNode
                    try {
                      this.nodeClickHandler(null, store.currentNode)
                    } catch (e){

                    }

                    this.currCatalogue = data
                    this.pageFormCopy.name = data.name
                    this.editId = data.id
                    this.dialogFormVisible = true
                  },
                  class: 'add-resource',
                },
                '编辑'),
              h(
                  'a',
                  {
                    style: 'margin-left: 8px',
                    onClick: () => {
                      this.remove(node, data)
                    },
                  },
                  '删除')
              ]
          )
      )
    },

  }
}
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.add-resource{
  margin-left: 10px;
  color: #3769B9;
}

.side-tree .el-tree-node.is-expanded > .el-tree-node__children {
  display: inline;
}

.side-tree .el-tree-node__content {
  background-color: #fff;
}

.side-tree .el-tree-node__content:hover{
   color: #3769B9;
 }

</style>

<style lang="scss" scoped>
.resource-outer-container{
  display: flex;
  .side-tree{
    height: 67vh;
    width: 300px;
    overflow: auto;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
  }
  .resource-container{
    position: relative;
    flex: 1;
    height: 100%;
    .empty-box{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #fff;
    }
    .resource-container-header{
      padding: 0 10px 10px;
    }
    .resource-content-container{
      width: 100%;
      max-height: 67vh;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: start;

      .resource-item{
        position: relative;
        margin: 0 10px;
        padding: 20px 0;
        width: 200px;
        height: 200px;
        .action-layer{
          position: absolute;
          left: 0;
          top: 0;
          display: none;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 8px;

          .font-class{
            color: #fff;
            font-size: 35px;
            font-weight: bold;
            cursor: pointer;
          }
        }
        &:hover{
          //background-color: #f5f5f6;
          //border-radius: 8px;
          .action-layer{
            display: flex;
          }
        }
        .avatar-container{
          position: relative;
          display: flex;
          align-content: center;
          justify-content: center;
        }
        .mask-layer{
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
        .info-box{
          cursor: default;
          .resource-title{
            color: #25262b;
          }
          .resource-time{
            font-size: 14px;
            color: #25262b5c;
          }
        }
      }
    }
  }
}

.select-resource-item{
  position: relative;
  width: 64px;
  height: 64px;
  font-size: 64px;
  &:hover{
    .select-resource-item-hover{
      display: flex!important;
    }
  }
  .select-resource-item-hover{
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    width: 64px;
    height: 64px;
    border-radius: 8px;
  }
}
</style>